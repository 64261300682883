import React from "react"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"
import BanerContact from "components/BanerContact"

import { Content } from "page_components/cart"

const Install = ({ location }) => {
  return (
    <Layout location={location}>
      <Breadcrumbs title="Cart" />
      <PageHeader title="Cart" />
      <Content />
      <BanerContact withMargin />
    </Layout>
  )
}

export const Head = () => <Seo title="Cart" />

export default Install
